import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./video2.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

const Video2 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [disableinteraction, setDisableInteraction] = useState(false);
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const cid = "UCoAOrXYmur6aLyQSxCtXo5Q";
  const channelURL = encodeURIComponent(`https://www.youtube.com/feeds/videos.xml?channel_id=${cid}`)
  const reqURL = `https://api.rss2json.com/v1/api.json?rss_url=${channelURL}`;
  const fetchInfo = async () => {
    const res = await fetch(reqURL);
    const d = await res.json();
    setLoading(false);
    return setData(d);
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    fetchInfo();
    };

    // Add event listener to update window width when the window is resized
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect (() => {
    windowWidth <= 740
      ? setDisableInteraction(true)
      : setDisableInteraction(false);
  },[windowWidth]);
  
  // console.log(disableinteraction);
  return (
    <div id="video" className="relative max-[640px]:mb-[70px]">
      <Swiper
        slidesPerView={3}
        spaceBetween={0}
        navigation={true}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: disableinteraction,
        }}
        modules={[Navigation, Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          740: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1125: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        className="mySwiper"
      >
        {
        loading ? (
         <p></p>
        ) :
        (
            data['items'].map((item) => {
            let id = item.guid.split(":")[2]
            let link = "https://www.youtube.com/embed/"+id;
            console.log(link);
            return (
               <SwiperSlide>
               <div className="my-[50px] w-[90%] h-[300px] max-[1125px]:w-[85%]">
               <iframe title = {item.title} className="w-[100%] h-[100%]" src=  {link}>
                </iframe>
               </div>
             </SwiperSlide>
                  );
          }
          )
        )
      }

        </Swiper>
    </div>
  );
};

export default Video2;
